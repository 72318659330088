import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DotIcon } from 'Assets/images/ellipse.svg';
import { ReactComponent as GoToIcon } from 'Assets/images/icon-forward.svg';
import './styles.scss';

const ActionMenuItem = ({ label, route, history }) => {
    return (
        <button className='action-menu-item' onClick={() => history.push(route)}>
            <DotIcon />
            <p className='action-menu-item-text'>{label}</p>
            <GoToIcon />
        </button>
    );
};

ActionMenuItem.propTypes = {
    label: PropTypes.string,
    route: PropTypes.string,
    history: PropTypes.object,
};

export default ActionMenuItem;
