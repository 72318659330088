import React from 'react';
import { ReactComponent as EngageWithLogo } from 'Assets/images/engagewith-certificate.svg';
import { ReactComponent as CertificateLogo } from 'Assets/images/certificate-logo.svg';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const Certificate = ({ awardName, awardee, awardDate, certificateRef }) => {
    const { workspace } = useSelector(mapStateToProps, shallowEqual);

    return (
        <div>
            <div ref={certificateRef} className='certificate-wrapper'>
                <div className='engagewith-logo'>
                    <EngageWithLogo height={'16px'} />
                </div>
                <div className='certificate-header'>
                    <CertificateLogo height={'90px'} width={'90px'} />
                    <span>{awardName}</span>
                </div>
                <div className='certificate-descrition'>
                    This <b>Honourable Certificate of Recognition</b> is proudly awarded to{' '}
                </div>
                <div className='certificate-awardee-section'>
                    <h1 className='certificate-awardee'>{awardee}</h1>
                    <p className='certificate-awardee-desc'>
                        Kudos to your perseverance, work ethics, ownership, and the invaluable efforts. You have aced
                        <br />
                        your work like no other and we are thankful for all that you do!
                    </p>
                </div>

                <div className='certificate-footer'>
                    <span>{awardDate}</span>
                    <div className='d-flex'>
                        {workspace.workspaceProfilePhoto && (
                            <img
                                src={workspace.workspaceProfilePhoto}
                                alt={workspace.workspaceName}
                                height={'40px'}
                                width={'40px'}
                            />
                        )}
                        <p className='certificate-workspace-name'>{workspace.workspaceName}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    workspace: Workspace,
});

Certificate.propTypes = {
    awardDate: PropTypes.string,
    awardName: PropTypes.string,
    awardee: PropTypes.string,
    certificateRef: PropTypes.object,
};
export default Certificate;
