import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import ActionMenuItem from './ActionMenuItem';
import { getQuickActions } from './constants';
import './styles.scss';

const Index = ({ history }) => {
    const { platform } = useSelector(mapStatesToProps, shallowEqual);
    const QUICK_ACTIONS = getQuickActions(platform.toLowerCase());
    return (
        <div className='action-menu-container'>
            <p className='action-menu-text'>Other Actions</p>
            <div className='action-menu-items'>
                {QUICK_ACTIONS.map(({ label, route }) => (
                    <ActionMenuItem key={label} label={label} route={route} history={history} />
                ))}
            </div>
        </div>
    );
};

const mapStatesToProps = ({ Workspace }) => ({
    platform: Workspace.platform,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
