import { format } from 'date-fns';
import React from 'react';
import VertMenu from 'components/ReusableComponents/VertMenu';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Tooltip } from 'components';
import { HISTORY_FROM, PLATFORM } from 'constants.js';
import { isSubscriptionCancelled } from 'utils/HelperFunctions';
import { ReactComponent as EditAwardIcon } from 'Assets/images/pulse.svg';
import { ReactComponent as DeleteIcon } from 'Assets/images/deleteIcon.svg';
import { BONUS_ROUTE } from './constants';
import MemberTag from './Components/MemberTag';
import AvatarGroupPopup from 'components/ReusableComponents/AvatarGroupPopup';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';

const goToProfile = (userId, history) => () => {
    if (userId) {
        handlePageRedirectionWithinSubMenu({
            history,
            redirectionURL: `${BONUS_ROUTE.PROFILE}${userId}`,
            navMenuCode: HISTORY_FROM.BONUS,
        })();
    }
};

export const rowFormatterForCompleted = (history) => (bonus) => ({
    id: bonus._id,
    row: [
        <Tooltip key={bonus._id} title={bonus.name}>
            <div className='bonus-name-cell'>{bonus.name}</div>
        </Tooltip>,
        <div key={bonus._id}>
            {bonus.awardee.length === 0 && 'N/A'}
            {bonus.awardee.length > 0 && (
                <MemberTag
                    history={history}
                    memberID={bonus.awardee[0]._id}
                    memberName={bonus.awardee[0].userName}
                    redirectToBonus={true}
                />
            )}
            {bonus.awardee.length > 1 && <span style={{ marginLeft: '5px', verticalAlign: 'middle' }}>&&nbsp;</span>}
            {bonus.awardee.length === 2 && (
                <MemberTag
                    history={history}
                    memberID={bonus.awardee[1]._id}
                    memberName={bonus.awardee[1].userName}
                    redirectToBonus={true}
                />
            )}
            {bonus.awardee.length > 2 && (
                <AvatarGroupPopup
                    assignedMembers={bonus.awardee.slice(1)}
                    moreUsers={true}
                    customHeight={'0px'}
                    marginLeft='-8px'
                    history={history}
                    fromLocation={HISTORY_FROM.BONUS}
                />
            )}
        </div>,
        bonus.launchDate ? format(new Date(bonus.launchDate), 'MMM dd, yyyy') : '-',
        <button
            className='link-btn'
            onClick={goToProfile(bonus.awardedBy._id, history)}
            key={`${bonus.awardedBy._id}-awardedBy`}
        >
            {bonus.awardedBy.userName}
        </button>,
        bonus.defaultAmount ? `${bonus.defaultCurrency} ${bonus.defaultAmount}` : 'N/A',
    ],
});

export const rowFormatterForScheduled =
    (optionMenu, selectedAward, history, subscription, setSubscriptionCancelled, platform) => (bonus) => ({
        id: bonus._id,
        row: [
            <Tooltip key={bonus._id} title={bonus.name}>
                <div className='award-name-cell'>{bonus.name}</div>
            </Tooltip>,
            <div key={bonus._id}>
                <MemberTag
                    history={history}
                    memberID={bonus.awardee[0]._id}
                    memberName={bonus.awardee[0].userName}
                    redirectToBonus={true}
                />
                {bonus.awardee.length > 1 && (
                    <span style={{ marginLeft: '5px', verticalAlign: 'middle' }}>&&nbsp;</span>
                )}
                {bonus.awardee.length === 2 && (
                    <MemberTag
                        history={history}
                        memberID={bonus.awardee[1]._id}
                        memberName={bonus.awardee[1].userName}
                        redirectToBonus={true}
                    />
                )}
                {bonus.awardee.length > 2 && (
                    <AvatarGroupPopup
                        assignedMembers={bonus.awardee.slice(1)}
                        moreUsers={true}
                        customHeight={'0px'}
                        marginLeft='-8px'
                        history={history}
                        fromLocation={HISTORY_FROM.BONUS}
                    />
                )}
            </div>,
            format(new Date(bonus.launchDate), 'MMM dd, yyyy'),
            <button className='link-btn' onClick={goToProfile(bonus.awardedBy._id, history)} key={bonus._id}>
                {bonus.awardedBy.userName}
            </button>,
            bonus.defaultAmount ? `${bonus.defaultCurrency} ${bonus.defaultAmount}` : 'N/A',
            platform !== PLATFORM.WEB && `${bonus.channel.name}`,
            <VertMenu
                key={bonus._id}
                placement={'bottom-end'}
                disabled={false}
                getSelected={(index) => {
                    if (optionMenu[index].value === 'editBonus') {
                        if (isSubscriptionCancelled(subscription)) {
                            setSubscriptionCancelled(true);
                            return;
                        }
                        history.push(BONUS_ROUTE.LAUNCH, { state: { id: bonus._id, edit: true } });
                    } else {
                        selectedAward.current = bonus;
                        if (optionMenu[index].value === 'deleteBonus') {
                            optionMenu[index].onClickHandler();
                        }
                    }
                }}
                optionMenu={optionMenu}
            >
                <MoreHorizIcon />
            </VertMenu>,
        ],
    });

export const threeDotMenuOptions = {
    edit: () => ({
        label: (
            <span>
                <EditAwardIcon className='mr-2 pointer-cursor' /> Edit Bonus details
            </span>
        ),
        value: 'editBonus',
    }),
    delete: (setOpenDeleteAwardModal) => ({
        label: (
            <span>
                <DeleteIcon className='mr-2 pointer-cursor' /> Delete
            </span>
        ),
        value: 'deleteBonus',
        onClickHandler: () => setOpenDeleteAwardModal(true),
    }),
};

export const getAnnouncementChannelString = (channelList, n) => {
    let retVal = channelList
        ?.slice(0, n)
        ?.map((c) => `#${c.channelName}`)
        ?.join(', ');
    if (channelList?.length > n) {
        retVal += `+${channelList.length - n}`;
    }
    return retVal;
};
