import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LabelWithTooltip from 'components/ReusableComponents/LabelWithTooltip';
import InputField from 'components/ReusableComponents/InputField';
import CustomUserTags from 'components/ReusableComponents/CustomUserTags';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import BonusPrize from './BonusPrize';
import { GET_CORE_VALUES } from 'Services/apiKeys';
import { getCoreValues, rateConversion } from 'Services/apiFunctions';
import { useQuery, useMutation } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { getCountries } from 'utils/countryUtils';
import EWButton from 'components/ReusableComponents/EWButton';
import UserSearchDropdown from 'components/ReusableComponents/UserSearchDropdown';
import ErrorField from 'components/ReusableComponents/ErrorField';

const CHAR_LIMIT = 60;
const ERROR_TEXT = `Bonus Name should be less than ${CHAR_LIMIT} characters`;

const BonusForm = ({ bonusDetails, setBonusDetails, workspaceCountry, editBonusId, workspaceCurrency }) => {
    const { SetSnackbar } = useToaster();
    const [values, setValues] = useState([]);
    const allCountries = getCountries();
    const [error, setError] = useState(false);

    const awardeesByCountry = bonusDetails.awardee.reduce((acc, item) => {
        if (item.userCountry && !acc.has(item.userCountry) && item.userCountry !== workspaceCountry) {
            acc.set(item.userCountry, item);
        }
        return acc;
    }, new Map());

    const { mutate: postRateConversion } = useMutation(rateConversion, {
        onSuccess: (data) => {
            let rates = data.data;
            const updatedBonusPrize = rates.map((rate) => ({
                currency: rate.currency,
                amount: parseFloat((rate.rate * bonusDetails.bonusAmount).toFixed(2)),
            }));
            setBonusDetails((prevDetails) => ({
                ...prevDetails,
                bonusPrize: updatedBonusPrize,
            }));
        },
    });

    const rateConversionHandler = () => {
        const apiData = {
            initialCurrency: bonusDetails.bonusCurrency,
            currencies: [...bonusDetails.bonusPrize.map((item) => item.currency)],
        };
        if (bonusDetails.awardee.length > 0) {
            postRateConversion({ apiData });
        }
    };

    useQuery([GET_CORE_VALUES], getCoreValues, {
        onSuccess: (data) => {
            const tempValues = data.values.map((value) => ({
                ...value,
                label: value.value,
                value: value.value,
            }));
            setValues(tempValues);
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
        keepPreviousData: true,
    });

    const handleSearchAwardee = (value) => {
        if (!value.userCountry) {
            showSnackBarMessage(SetSnackbar, 'error', 'Awardees must be assigned to a country');
            return;
        }
        // setting rate - when editing and adding user belonging to workspace currency
        if (editBonusId && value.userCountry === workspaceCountry) {
            value.rate = bonusDetails.bonusPrize.amount;
        }
        setBonusDetails((prevDetails) => {
            const awardeeExists = prevDetails.awardee.some(
                (awardee) => awardee.id === value._id || awardee._id === value._id
            );
            const currencyExists = prevDetails.bonusPrize.some((bonus) => bonus.currency === value.userCurrency);

            let updatedAwardees;
            let updatedBonusPrize = [...prevDetails.bonusPrize];

            if (awardeeExists) {
                // If awardee exists, remove the awardee
                updatedAwardees = prevDetails.awardee.filter((awardee) =>
                    awardee.id ? awardee.id !== value._id : awardee._id !== value._id
                );

                // Check if any other awardees have the same currency as removed awardee currency
                const otherAwardeesWithSameCurrency = updatedAwardees.some(
                    (awardee) => awardee.userCurrency === value.userCurrency
                );

                // If no awardees have the same currency, remove the currency from bonusPrize
                if (!otherAwardeesWithSameCurrency) {
                    updatedBonusPrize = updatedBonusPrize.filter((bonus) => bonus.currency !== value.userCurrency);
                }
            } else {
                // Add the new awardee
                updatedAwardees = [...prevDetails.awardee, value];
            }

            return {
                ...prevDetails,
                awardee: updatedAwardees,
                bonusPrize: currencyExists
                    ? updatedBonusPrize
                    : [...updatedBonusPrize, { currency: value.userCurrency, amount: 0 }],
            };
        });
    };

    //callback on removing selected winner
    const onRemoveAwardee = (data) => {
        const removedAwardee = bonusDetails.awardee.find((awardee) => !data.some((d) => d._id === awardee._id));

        //check if any awardee have same currency as removed awardee
        const otherAwardeesWithSameCurrency = data.filter((item) => item.userCurrency === removedAwardee.userCurrency);

        setBonusDetails((prevDetails) => {
            // If no other awardees share the same currency, filter out that bonus prize
            const updatedBonusPrize =
                otherAwardeesWithSameCurrency.length === 0
                    ? prevDetails.bonusPrize.filter((prize) => prize.currency !== removedAwardee.userCurrency)
                    : prevDetails.bonusPrize;

            return {
                ...prevDetails,
                awardee: data,
                bonusPrize: updatedBonusPrize,
            };
        });
    };

    const handleAwardMessage = (inputID, value) => {
        setBonusDetails((prevDetails) => ({
            ...prevDetails,
            [inputID]: value,
        }));
    };

    const handleInputChange = (inputId, value) => {
        if (value.trim().length <= CHAR_LIMIT) {
            setError(false);
            setBonusDetails((prevDetails) => ({
                ...prevDetails,
                [inputId]: value,
            }));
        } else {
            setError(true);
        }
    };

    const bonusAmountHandler = (e, item) => {
        const value = parseInt(e.target.value);
        // iterate through all bonus prizes and update respective bonus prize amount
        setBonusDetails((prevDetails) => {
            const updatedBonusPrize = prevDetails.bonusPrize.map((prize) => {
                if (prize.currency === item.userCurrency) {
                    return { ...prize, amount: value };
                }
                return prize;
            });

            return {
                ...prevDetails,
                bonusPrize: updatedBonusPrize,
            };
        });
    };

    const handleCoreValues = (_index, value) => {
        setBonusDetails((prevDetails) => ({
            ...prevDetails,
            companyValues: value,
        }));
    };

    const handleBonusPrize = (value) => {
        const valueString = value.toString();

        // Check if the value contains a decimal point
        if (valueString.includes('.')) {
            const decimalPart = valueString.split('.')[1];

            // Limit the decimal part to 2 digits
            if (decimalPart.length >= 3) {
                return;
            }
        }
        setBonusDetails((prevDetails) => {
            const updatedBonusPrize = prevDetails.bonusPrize.map((bonus) => {
                if (bonus.currency === workspaceCurrency) {
                    return { ...bonus, amount: value };
                }
                return bonus;
            });

            return {
                ...prevDetails,
                bonusAmount: value,
                bonusPrize: updatedBonusPrize,
            };
        });
    };

    useEffect(() => {
        if (bonusDetails.bonusAmount) {
            rateConversionHandler();
        }
    }, [bonusDetails.bonusAmount]);

    return (
        <div className='select-bonus-winner'>
            <InputField
                inputID='bonusName'
                label='Name the bonus'
                placeholder='Enter a name for this Bonus'
                width='500px'
                height='40px'
                value={bonusDetails.bonusName || ''}
                handleChange={handleInputChange}
                inputClass={error && 'border-red'}
            />
            {error && <ErrorField warningIcon={false} errorText={ERROR_TEXT} className='error-field-bonus-name' />}
            <LabelWithTooltip text='Who will be receiving this Bonus?' />
            <div className='sdw-searchAwardee'>
                <UserSearchDropdown
                    dropDownID='searchAwardee'
                    dropdownWidth={500}
                    dropdownHeight={200}
                    width={500}
                    selectedUsers={bonusDetails.awardee}
                    handleUserSelection={handleSearchAwardee}
                    placeholder='Select Awardees'
                    customAddSearch={true}
                />
            </div>
            <CustomUserTags users={bonusDetails.awardee} onRemove={onRemoveAwardee} />
            <InputField
                inputID='message'
                label='Why do you want to give Bonus to the selected?'
                placeholder='Write a reason to give the Bonus'
                width='500px'
                height='80px'
                handleChange={handleAwardMessage}
                value={bonusDetails.message}
                textArea
            />
            {values.length > 0 && (
                <CustomFilterDropdown
                    title='Company Value Highlighted'
                    titleStyleClass='csr-modal-filter-label'
                    search
                    dropDownID='company-value'
                    filterOptions={values}
                    selectedName={bonusDetails.companyValues.value || 'Select company value'}
                    handleSelection={handleCoreValues}
                    singleSelect
                    optionsSelected={!!bonusDetails.companyValues.value}
                    buttonStyleClass='width-500'
                    dropdownWidth={'500px'}
                    dropdownGap={25}
                />
            )}
            <BonusPrize
                bonusPrize={bonusDetails.bonusPrize}
                workspaceCountry={workspaceCountry}
                handleBonusPrize={handleBonusPrize}
                bonusPrizeAmount={bonusDetails.bonusAmount}
            />
            {awardeesByCountry.size > 0 ? (
                <div className='bonus-prize-other-countries'>
                    <LabelWithTooltip
                        text='Bonus Prize for other countries'
                        tooltipText='This is the Bonus prize that will be awarded to the winner in their EngageWith wallet'
                    />
                    <div className='ppp-wrapper'>
                        <div className='bonus-input-wrapper'>
                            {[...awardeesByCountry.values()].map((item) => (
                                <div className='bonus-input-container' key={item.userCountry}>
                                    <input
                                        className='bonus-input-value'
                                        placeholder='Enter amount'
                                        value={
                                            bonusDetails.bonusPrize.find(
                                                (bonusPrize) =>
                                                    bonusPrize.currency === item.userCurrency ||
                                                    bonusPrize.userCountry === item.userCountry
                                            ).amount
                                        }
                                        type='number'
                                        onChange={(e) => bonusAmountHandler(e, item)}
                                    />
                                    <div className='flag-currency'>
                                        <img
                                            src={
                                                allCountries?.find(
                                                    (country) => country?.countryCode === item?.userCountry
                                                )?.countryFlag
                                            }
                                            alt=''
                                        />
                                        {item.userCurrency}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <div className='note-wrapper'>
                                <b>Note:</b> You have selected users from {[...awardeesByCountry.values()].length}{' '}
                                different countries. To ensure fairness and account for differences in Purchasing Power
                                Parity (PPP), add calculated and adjusted prize amount.
                            </div>
                            <EWButton primary={false} plainTextButton={true} onClick={rateConversionHandler}>
                                💡 Calculate using PPP logic
                            </EWButton>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

BonusForm.propTypes = {
    bonusDetails: PropTypes.object,
    setBonusDetails: PropTypes.func,
    workspaceCountry: PropTypes.object,
    editBonusId: PropTypes.bool,
    workspaceCurrency: PropTypes.string,
};

export default BonusForm;
