export const BONUS_ROUTE = {
    HOME: '/dashboard/bonus',
    LAUNCH: '/dashboard/bonus/launch',
    PROFILE: '/dashboard/profile/',
};

export const SCHEDULED_TABLE_PROPERTIES = {
    HEADERS: ['BONUS NAME', 'AWARDEE', 'LAUNCH DATE', 'GIVEN BY', 'PRIZE', 'ANNOUNCE ON'],
    NO_SORT: [1, 3, 4, 5],
    SORT_KEY: ['name', 'awardee', 'launchDate', 'sentBy', 'amount', 'givenOn'],
    COLUMN_WIDTH: ['18%', '18%', '18%', '18%', '10%', '18%'],
};

export const COMPLETED_TABLE_PROPERTIES = {
    HEADERS: ['BONUS NAME', 'AWARDEE', 'RECEIVED ON', 'GIVEN BY', 'PRIZE'],
    NO_SORT: [1, 3, 4],
    SORT_KEY: ['name', 'awardee', 'launchDate', 'sentBy', 'amount'],
    COLUMN_WIDTH: ['22%', '22%', '22%', '22%', '12%'],
};

export const BONUS_STATUS = {
    SCHEDULED: 'scheduled',
    COMPLETED: 'completed',
};
export const BONUS_STATUS_TO_DATE_FILTER_TYPE_MAP = {
    [BONUS_STATUS.SCHEDULED]: 'launchDate',
    [BONUS_STATUS.COMPLETED]: 'awardDate', // need to changes
};

export const STEPPERS = ['Bonus details', 'Announcement details'];
export const BONUS_PRIZE = {
    AMOUNT: 'amount',
    CURRENCY: 'currency',
};
