import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import EWModal from 'components/ReusableComponents/EWModal';
import PropTypes from 'prop-types';
import './style.scss';
import Certificate from './Certificate';

const Index = ({ open, onClose, awardDate, awardName, awardee }) => {
    const certificateRef = useRef();

    if (!open) {
        return null;
    }
    const downloadCertificate = () => {
        html2canvas(certificateRef.current).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('landscape');
            pdf.addImage(imgData, 'PNG', 0, 0, 297, 210);
            // pdf.save('certificate.pdf');
            const pdfBlob = pdf.output('blob');
            const url = URL.createObjectURL(pdfBlob);
            window.open(url, '_blank');
        });
    };
    const modalData = {
        onClose: onClose,
        rightButtonText: 'Download Certificate',
        handleRightButtonClick: downloadCertificate,
    };

    return (
        <EWModal open={open} onClose={onClose} width='810px' height='700px' modalData={modalData}>
            <div className='certificate-container'>
                <Certificate
                    certificateRef={certificateRef}
                    awardDate={awardDate}
                    awardName={awardName}
                    awardee={awardee}
                />
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    awardDate: PropTypes.string,
    awardName: PropTypes.string,
    awardee: PropTypes.string,
};

export default Index;
