import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    showSnackBarMessage,
    timeInterval,
    getIntegratedChannelName,
    getChannelOptionsByPlatform,
} from 'utils/HelperFunctions';
import LabelWithTooltip from 'components/ReusableComponents/LabelWithTooltip';
import CustomDatePicker from 'components/ReusableComponents/CustomDatePicker';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import './style.scss';
import { isToday } from 'date-fns';
import { useToaster } from 'Context/SnackbarContext';
import { GET_INTEGRATED_CHANNELS } from 'Services/apiKeys';
import { getIntegratedChannelsList } from 'Services/apiFunctions';
import { useQuery } from 'react-query';
import AnouncementRadioSection from './AnouncementRadioSection';
import { PLATFORM } from 'constants.js';

const bonusTimeText = 'Bonus will be announced as per the orgnazination time zone IST/GMT+5:30';
const bonusDateText = 'When do you want to announce this Bonus?';

const Index = ({ bonusDetails, announcement, setBonusDetails, platform }) => {
    const { SetSnackbar } = useToaster();
    const [time, setTime] = useState(timeInterval(new Date()));
    const [channelList, setChannelList] = useState([]);

    const onError = (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message);

    useQuery([GET_INTEGRATED_CHANNELS], getIntegratedChannelsList, {
        onSuccess: (data) => {
            if (data?.length) {
                setChannelList(getChannelOptionsByPlatform(data, platform));
            }
        },
        onError,
    });

    if (announcement.sendInstant === true) {
        announcement.time = 'Send instantly';
    }

    const handleLaunchDate = (date) => {
        if (isToday(date) && time?.length < 1) {
            showSnackBarMessage(SetSnackbar, 'error', "No time slots available for today's date");
            return;
        }
        setTime(timeInterval(date));
        setBonusDetails((prevDetails) => ({
            ...prevDetails,
            announcement: {
                ...prevDetails.announcement,
                time: isToday(date) ? 'Send instantly' : '12:00 AM',
                date: date,
            },
        }));
    };

    const handleLaunchTime = (value) =>
        setBonusDetails((prevDetails) => ({
            ...prevDetails,
            announcement: {
                ...prevDetails.announcement,
                time: time[value].label,
            },
        }));

    const handleChannelSelection = (_index, channel) => {
        setBonusDetails((prevDetails) => ({
            ...prevDetails,
            announcement: {
                ...prevDetails.announcement,
                channelId: channel.channelID || channel.channelId,
                ...(platform === PLATFORM.SLACK && { channelName: channel.channelName }),
                ...(platform === PLATFORM.TEAMS && {
                    channelName: `${channel.groupName} \u2192 ${channel.channelName}`,
                    groupId: channel.groupId,
                }),
            },
        }));
    };

    return (
        <div className='container-announcement-details'>
            <div className='cad-left-container'>
                <h5>Announcement details</h5>
                <LabelWithTooltip text={bonusDateText} />
                <div className='container-date'>
                    <CustomDatePicker
                        value={announcement.date || null}
                        placeholder='Select Date'
                        onChange={handleLaunchDate}
                        format={'MMMM dd, yyyy'}
                        className='date-selector'
                    />
                    <CustomFilterDropdown
                        dropDownID='launchtime'
                        selectedName={announcement.time || 'Select Time'}
                        buttonStyleClass='width-240'
                        filterOptions={time}
                        optionsSelected
                        handleSelection={handleLaunchTime}
                        singleSelect
                        clock
                    />
                </div>
                <p className='poll-active-text'>{bonusTimeText}</p>
                {platform !== PLATFORM.WEB && (
                    <div className='d-flex flex-column mt-1'>
                        <LabelWithTooltip text='In which channel do you want to celebrate this Bonus?' />
                        <CustomFilterDropdown
                            dropDownID='launchChannel'
                            selectedName={
                                announcement.channelName
                                    ? announcement.channelName
                                    : 'Select Channel to celebrate Bonus'
                            }
                            buttonStyleClass='width-500'
                            filterOptions={channelList}
                            optionsSelected={!!announcement?.channelName}
                            handleSelection={handleChannelSelection}
                            singleSelect
                            search
                        />
                    </div>
                )}
                <AnouncementRadioSection bonusDetails={bonusDetails} setBonusDetails={setBonusDetails} />
            </div>
        </div>
    );
};

Index.propTypes = {
    bonusDetails: PropTypes.object,
    announcement: PropTypes.object,
    setBonusDetails: PropTypes.func,
    platform: PropTypes.string,
};

export default Index;
